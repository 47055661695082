


















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Datepicker from 'vuejs-datepicker';

export type dateChangedEvent = {
  newDate: Date;
  oldDate: Date;
};

@Component({
  components: {
    datepicker: Datepicker
  }
})
export default class DatepickerComponent extends Vue {
  @Prop({ default: '' }) id!: string;
  @Prop({ default: '' }) inputClass!: string;
  @Prop({ default: null }) fromDate!: string | null | Date;
  @Prop({ default: null }) toDate!: string | null | Date;
  @Prop({ default: null }) selectedDate!: string | null | Date;


  previousSelectedDate: Date | null = null;
  currentDate: Date | null = null;

  dateChanged(newDate: Date | string) {
    newDate = new Date(newDate);

    const event: dateChangedEvent = {
      newDate: newDate,
      oldDate: this.previousSelectedDate as Date
    };

    this.$emit('onDateChange', event);

    this.previousSelectedDate = newDate;
  }

  openDatepicker() {
    (this.$refs.datepicker as any).showCalendar();
  }

  customFormatter(date: Date) {
    if (!date) {
      return '';
    }
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  get computedDisabledDates() {
    if (this.fromDate && this.toDate) {
      return {
        from: new Date(new Date(this.toDate).setHours(0, 0, 0, 0)),
        to: new Date(new Date(this.fromDate).setHours(0, 0, 0, 0))
      };
    }
    return {};
  }

  @Watch('selectedDate', { immediate: true, deep: true })
  dateChange(e: any) {
    this.currentDate = e;
  }

  mounted() {
    const currentDate = new Date();
    this.previousSelectedDate = currentDate;
    if (this.selectedDate == null) {
      this.currentDate = currentDate;
    }
    else if (!(this.selectedDate instanceof Date)) {
      this.currentDate = new Date(this.selectedDate);
    }

    if (this.fromDate) {
      const fromDate =
        this.fromDate instanceof Date
          ? this.fromDate
          : new Date(this.fromDate);
      if (currentDate < fromDate) {
        this.previousSelectedDate = fromDate;
        this.currentDate = fromDate;
        return;
      }
    }

    if (this.toDate) {
      const toDate =
        this.toDate instanceof Date
          ? this.toDate
          : new Date(this.toDate);
      if (currentDate > toDate) {
        this.previousSelectedDate = toDate;
        this.currentDate = toDate;
        return;
      }
    }
  }
}
